import AxiosUtility, { setAuthToken } from "../redux/actions/axiosService";

/**
 * @link https://pickupmtaani.atlassian.net/jira/software/c/projects/DEV/issues/DEV-368
 *
 * @param {string} agent_id
 * @desc updates agent's activation state to activated
 * @return {Promise<any>}
 */
const activateAgent = async (agent_id) => {
  await setAuthToken(AxiosUtility);

  let res = await AxiosUtility.put(
    `/api/activate-deactivate/${agent_id}?status=true`
  );

  return res?.data;
};

const AgentServices = {
  activateAgent,
};
export default AgentServices;
